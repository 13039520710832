<template>
  <div class="form-floating">
  <v-select class="form-control" :options="paises" @option:selected="setPais"
  @change="setPais" v-model="pais">></v-select>
  <label for="floatingSelect">{{titulo ? titulo : 'País'}} <span class="text-danger fw-bold">[*]</span></label>
  </div>
</template>

<script setup>
const props = defineProps({
  titulo: String,
  pais_select: String
})

import {ref, onMounted} from 'vue'

import { getPaises } from '@/helpers';

const paises = ref([])

const pais = ref(props.pais_select)

const emit = defineEmits(['onSetPais'])

const setPais = () => {
emit('onSetPais', pais.value)
}

onMounted(async () => {
 const paisesData = await getPaises()
  let jsonPaises = []
  for(let i in paisesData){
    jsonPaises.push(paisesData[i].pais)
  }
  paises.value = jsonPaises
})
</script>