<template>
  <div class="nav" :class="`${toggleSider ? 'show-menu' : ''}`">
    <nav class="nav__container">
      <div>
        <a @click="$router.push('/')" class="nav__link nav__logo c-pointer">
          <img
            style="margin:auto; width: 35px"
            src="@/assets/img/logo/logo.png"
            alt="Web App"
          />
        </a>

        <div class="nav__list">
          <div class="nav__items">

            <router-link to="/" class="nav__link">
              <i class="fa-solid fa-house nav__icon"></i>
              <span class="nav__name">Inicio</span>
            </router-link>

            <router-link to="/oficinas" class="nav__link"
             v-if="$auth.user.rol === 'admin' || $auth.user.rol === 'agente'"
            >
              <i class="fa-solid fa-building nav__icon"></i>
              <span class="nav__name">Oficinas</span>
            </router-link>


             <router-link to="/courier" class="nav__link"
             v-if="$auth.user.rol === 'admin' || $auth.user.rol === 'agente'"
             >
              <i class="fa-solid fa-truck nav__icon"></i>
              <span class="nav__name">Courier</span>
            </router-link>



           <div class="nav__dropdown"
           v-if="false && $auth.user.rol === 'admin' || $auth.user.rol === 'agente'"
           >
              <a href="#" class="nav__link">
                <i class="fa-solid fa-tags nav__icon"></i>
                <span class="nav__name">Categorías</span>
                <i class="fa-solid fa-angle-down nav__icon nav__dropdown-icon"></i>
              </a>

             <div class="nav__dropdown-collapse">
                <div class="nav__dropdown-content">

                  <router-link to="/categorias" class="nav__dropdown-item">
                    <i class="fa-solid fa-tag nav__icon"></i>
                    <span class="nav__name">Actuales</span>
               </router-link>

                   <router-link to="/sub-categorias" class="nav__dropdown-item">
                    <i class="fa-solid fa-bars-staggered nav__icon"></i>
                    <span class="nav__name">Sub Categ</span>
               </router-link>

                </div>
             </div>

           </div>

            <div class="nav__dropdown">
              <a href="#" class="nav__link">
                <i class="fa-solid fa-file-lines nav__icon"></i>
                 <span class="nav__name">Guías</span>
                <i class="fa-solid fa-angle-down nav__icon nav__dropdown-icon"></i>
              </a>

              <div class="nav__dropdown-collapse">
                <div class="nav__dropdown-content">

                  <router-link to="/guias-aereas" class="nav__dropdown-item">
                    <i class="fa-solid fa-plane-departure nav__icon"></i>
                    <span class="nav__name">Aéreas</span>
                  </router-link>

                  <router-link to="/guias-maritimas" class="nav__dropdown-item">
                    <i class="fa-solid fa-ship nav__icon"></i>
                    <span class="nav__name">Marítimas</span>
                  </router-link>



                  <router-link to="/manifiestos" class="nav__dropdown-item">
                   <i class="fa-solid fa-folder-open nav__icon"></i>
                    <span class="nav__name">Manifiestos</span>
                  </router-link>


                  <router-link to="/recepciones" class="nav__dropdown-item" v-if="$auth.user.rol === 'admin' || $auth.user.rol === 'agente'">
                    <i class="fa-solid fa-clipboard-check nav__icon"></i>
                     <span class="nav__name">Recepciones</span>
                   </router-link>
 

                   <router-link to="/cargas" class="nav__dropdown-item" v-if="$auth.user.rol === 'admin'">
                    <i class="fa-solid fa-box-open nav__icon"></i>
                    <span class="nav__name">Cargas</span>
                  </router-link>

                  <a class="nav__dropdown-item c-pointer" @click="modalActiveCalculadoraEnvios = true">
                    <i class="fa-solid fa-calculator nav__icon"></i>
                    <span class="nav__name">Calculadora</span>
               </a>

                </div>
              </div>
            </div>


             <div class="nav__dropdown">

              <a href="#" class="nav__link">
                <i class="fa-solid fa-screwdriver-wrench nav__icon"></i>
                <span class="nav__name">Herramientas</span>
                <i class="fa-solid fa-angle-down nav__icon nav__dropdown-icon"></i>
              </a>

             <div class="nav__dropdown-collapse">
                <div class="nav__dropdown-content">


             <a class="nav__dropdown-item c-pointer" @click="toggleModalCalculadoraDivisas">
                    <i class="fa-solid fa-calculator nav__icon"></i>
                    <span class="nav__name">Calculadora Divisas</span>
               </a>


               <a class="nav__dropdown-item c-pointer" @click="toggleModalTrackingGuia">
                    <i class="fa-solid fa-magnifying-glass-location nav__icon"></i>
                    <span class="nav__name">Tracking Guias</span>
                  </a>


                  <a class="nav__dropdown-item c-pointer" @click="toggleModalTrackingZoom">
                    <i class="fa-solid fa-magnifying-glass-location nav__icon"></i>
                    <span class="nav__name">Tracking Zoom</span>
                  </a>
        

        
                  <a class="nav__dropdown-item c-pointer" @click="toggleModalTrackingTealca">
                    <i class="fa-solid fa-magnifying-glass-location nav__icon"></i>
                    <span class="nav__name">Tracking Tealca</span>
                  </a>

                   <a class="nav__dropdown-item c-pointer" @click="toggleModalTrackingMrwVe">
                    <i class="fa-solid fa-magnifying-glass-location nav__icon"></i>
                    <span class="nav__name">Tracking MRW-VE</span>
                  </a>

                   <a class="nav__dropdown-item c-pointer" @click="toggleModalImeiOsiptel">
                    <i class="fa-solid fa-mobile nav__icon"></i>
                    <span class="nav__name">Imei Osiptel</span>
               </a>

                </div>
             </div>

           </div>

            <router-link to="/clientes" class="nav__link" 
             v-if="$auth.user.rol === 'admin'">
              <i class="fa-solid fa-users nav__icon"></i>
              <span class="nav__name">Clientes</span>
            </router-link>
          
            <router-link to="/usuarios" class="nav__link"
             v-if="$auth.user.rol === 'admin'"
            >
            <i class="fa-solid fa-user nav__icon"></i>
            <span class="nav__name">Usuarios</span>
            </router-link>

         

       <router-link to="/configuracion" class="nav__link"
       v-if="$auth.user.rol === 'admin' || $auth.user.rol === 'agente'"
      >
        <i class="fa-solid fa-gear nav__icon"></i>
        <span class="nav__name">Configuración</span>
      </router-link>

          </div>
        </div>
      </div>

    <!-- Fixed Icon Bottom -->

    <!----->
    </nav>

<ModalTrackingGuia class="animate__animated animate__fadeIn animate__faster" @toggleModal="toggleModalTrackingGuia()" v-if="modalActiveTrackingGuia" />

<ModalTrackingZoom class="animate__animated animate__fadeIn animate__faster" @toggleModal="toggleModalTrackingZoom()" v-if="modalActiveTrackingZoom" />

<ModalTrackingTealca class="animate__animated animate__fadeIn animate__faster" @toggleModal="toggleModalTrackingTealca()" v-if="modalActiveTrackingTealca" />

<ModalTrackingMrwVe class="animate__animated animate__fadeIn animate__faster" @toggleModal="toggleModalTrackingMrwVe()" v-if="modalActiveTrackingMrwVe" />

<ModalImeiOsiptel class="animate__animated animate__fadeIn animate__faster" @toggleModal="toggleModalImeiOsiptel()" v-if="modalActiveImeiOsiptel" />

<ModalCalculadoraDivisas class="animate__animated animate__fadeIn animate__faster" @toggleModal="toggleModalCalculadoraDivisas()" v-if="modalActiveCalculadoraDivisas" />

<ModalCalculadoraEnvios class="animate__animated animate__fadeIn animate__faster" @toggleModal="modalActiveCalculadoraEnvios = !modalActiveCalculadoraEnvios" v-if="modalActiveCalculadoraEnvios" />
</div>
</template>

<script setup>
import ModalTrackingZoom from '@/components/modals/tracking/Tracking-Guias-Zoom.vue'
import ModalTrackingTealca from '@/components/modals/tracking/Tracking-Guias-Tealca.vue'
import ModalTrackingMrwVe from '@/components/modals/tracking/Tracking-Guias-MrwVe.vue'
import ModalImeiOsiptel from '@/components/modals/imei-osiptel/Consulta-Imei.vue'
import ModalTrackingGuia from '@/components/modals/tracking/Tracking-By-Guia.vue'
import ModalCalculadoraDivisas from '@/components/modals/calculadora-divisas/Calculadora-Divisas.vue'
import ModalCalculadoraEnvios from '@/components/modals/calculadora-envios/Calculadora-Envios.vue'
import {ref} from 'vue'

const modalActiveCalculadoraEnvios = ref(false)
const props = defineProps({
  toggleSider: Boolean
})


const modalActiveTrackingZoom= ref(false)
const toggleModalTrackingZoom = () => {
  modalActiveTrackingZoom.value = !modalActiveTrackingZoom.value
}


const modalActiveTrackingTealca = ref(false)
const toggleModalTrackingTealca = () => {
   modalActiveTrackingTealca.value = !modalActiveTrackingTealca.value
}


const modalActiveTrackingMrwVe = ref(false)
const toggleModalTrackingMrwVe = () => {
   modalActiveTrackingMrwVe.value = !modalActiveTrackingMrwVe.value
}


const modalActiveImeiOsiptel = ref(false)
const toggleModalImeiOsiptel = () => {
   modalActiveImeiOsiptel.value = !modalActiveImeiOsiptel.value
}


const modalActiveTrackingGuia = ref(false)
const toggleModalTrackingGuia = () => {
  modalActiveTrackingGuia.value = !modalActiveTrackingGuia.value
}


const modalActiveCalculadoraDivisas = ref(false)
const toggleModalCalculadoraDivisas = () => {
  modalActiveCalculadoraDivisas.value = !modalActiveCalculadoraDivisas.value
}
</script>