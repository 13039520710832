<template>
<Modal :titulo="titulo" @close="$emit('toggleModal')" :modalActive="true">
    <div>
            <div class="row">

              <div class="col-md-6 mb-3">
                <SelectPaises v-if="resetFieldPais" :pais_select="tasaEnvioData.pais" @onSetPais="setPais($event)"/>
              </div>


               <div class="col-md-6 mb-3">
                <div class="form-floating">
              <select class="form-select" v-model="tasaEnvioData.divisa" @change="getSimbolDivisa">
                <option v-for="item in divisas" :key="item.id" :value="item.divisa">{{item.divisa}}</option>
              </select>
              <label for="floatingSelect">Divisa  <span class="text-danger fw-bold">[*]</span></label>
            </div>
              </div>


              
          <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="tasaEnvioData.nombre" type="text" class="form-control" id="floatingInput2" placeholder="Nombre Tasa" autocomplete="off">
               <label for="floatingInput2">Nombre de la tasa<span class="text-danger fw-bold">[*]</span></label>
              </div>
                </div>



             <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="tasaEnvioData.tasa" type="number" class="form-control text-center fs-xl-2" id="floatingInput2" placeholder="Tasa de cambio" autocomplete="off">
               <label for="floatingInput2">Tasa<span class="text-danger fw-bold">[*]</span></label>
              </div>
              </div>



               <div class="col-md-12 mb-3">
                <div class="form-floating">
              <select class="form-select" v-model="tasaEnvioData.tipo_guia">
                <option v-for="item in tipos_envios" :key="item.id" :value="item.id">{{item.envio}}</option>
              </select>
              <label for="floatingSelect">Tipo De Envio  <span class="text-danger fw-bold">[*]</span></label>
            </div>
              </div>

            <div >
             <div class="form-check d-flex justify-content-center fs-xl-2 mb-2">
             <input class="form-check-input" type="checkbox" id="checkPromocion" v-model="tasaEnvioData.promocion">
             <label class="form-check-label ms-2" for="checkPromocion">
                 Precio Fijo (Promocion)?
             </label>
             </div>

             <p class="text-danger fw-bold">
              Se cobrara el precio que se establesca en el campo de tasa de manera fija 
              sea cual sea las medidas o peso de la caja
             </p>

             </div>


             <div v-if="tasaEnvioData.tipo_guia == 1">
             <div class="form-check d-flex justify-content-center fs-xl-2 mb-2">
             <input class="form-check-input" type="checkbox" id="checkCalculado" v-model="tasaEnvioData.calculado">
             <label class="form-check-label ms-2" for="checkCalculado">
                 Precio Calculado?
             </label>
             </div>

             <p class="text-danger fw-bold">(En escenarios maritimos si deseas calcular el peso / m3 activar la casilla de Calculado)</p>

             </div>

            </div>
        

              </div>
 

            <div class="buttons-modal-footer">
            <button v-if="modo==0" @click="Crear" type="button" class="btn-modal-crear w-90 b-radius">Crear</button>
            <button v-if="modo==1" @click="actualizarRegistro" type="button" class="btn-modal-crear w-90 b-radius">Actualizar</button>
             </div>
</Modal> 
</template>

<script setup>
import axios from 'axios'
import SelectPaises from '@/components/dashboard/selectPaises.vue'
import Modal from '@/components/modals/Modal.vue'
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
import { useToast } from 'vue-toastification';
const toast = useToast();
import {ref, onMounted, reactive, defineEmits, defineProps} from 'vue'
const modulo = ref("tasa-envios");
import { dataDivisaByPais, getTiposEnvios } from '@/helpers';

const props = defineProps({
    titulo: null,
    modo: null,
    id: null,
    tipo_guia: null,
})

const emit = defineEmits(['toggleModal', 'status'])

const paises = ref([])
const divisas = ref([])
const tipos_envios = ref([])
const resetFieldPais = ref(true)

const setPais = (e) => {
tasaEnvioData.pais = e
getDivisaData()
}

const getDivisaData = () => {
try{
let divisaFilter = dataDivisaByPais(tasaEnvioData.pais)
divisas.value = divisaFilter.divisas
}catch (error){
  divisas.value = []
  toast.info('El pais seleccionado no se encuentra habilitado')
}
}

const getSimbolDivisa = () => {
let divisaFilter = dataDivisaByPais(tasaEnvioData.pais)
let simbolFilter = divisaFilter.divisas.filter(item => item.divisa == tasaEnvioData.divisa)[0]
tasaEnvioData.simbolo = simbolFilter.simbolo
}

const tasaEnvioData = reactive({
  tipo_guia: props.tipo_guia,
  pais: '',
  nombre: '',
  tasa: '',
  promocion: '',
  calculado: '',
  divisa: '',
  simbolo: '',
})


const resetModal = () => {
divisas.value = []
tasaEnvioData.nombre = ''
tasaEnvioData.divisa = ''
tasaEnvioData.pais = ''
tasaEnvioData.tasa = ''
tasaEnvioData.promocion = ''
tasaEnvioData.calculado = ''
tasaEnvioData.tipo_guia = ''
}

onMounted(() => {
    if(props.modo == 0){
        tipos_envios.value = getTiposEnvios()
    }else{
        tipos_envios.value = getTiposEnvios()
        getTasaEnvioById(props.id)
    }  
})

const Crear = () => {
let loader = $loading.show();
axios.post(modulo.value, tasaEnvioData)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              emit('toggleModal')
              emit('status')
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Creando Registro')
    });

}

const actualizarRegistro = () => {
  let loader = $loading.show();
  axios.put(`${modulo.value}/${props.id}`, tasaEnvioData)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              emit('toggleModal')
              emit('status')
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Actualizando Registro')
    });
}

const getTasaEnvioById = async (id) => {
  let response = (await axios.get(`${modulo.value}/${id}`)).data
  tasaEnvioData.nombre = response.nombre
  tasaEnvioData.pais = response.pais
  tasaEnvioData.divisa = response.divisa
  tasaEnvioData.simbolo = response.divisa_simbolo
  tasaEnvioData.tasa = response.tasa
  tasaEnvioData.tipo_guia = response.tipo_guia

  if(response.promocion == 1){
  tasaEnvioData.promocion = true
  }else{
  tasaEnvioData.promocion = 0
  }

  if(response.calculado == 1){
  tasaEnvioData.calculado = true
  }else{
  tasaEnvioData.calculado = 0
  }
  getDivisaData()
  getSimbolDivisa()
  resetFieldPais.value = false
  setTimeout(() => {
  resetFieldPais.value = true
  }, 30);
}
</script>