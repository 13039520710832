<template>
 <div>
   <div class="form-floating position-relative">
    <vue3-simple-typeahead
    v-if="reloadTypehead"
    class="form-control fs-xl-2 text-center"
    id="typeahead_documentos_clientes"
    :items="documentosClientes"
    :itemProjection="item => item.documento"
    :minInputLength="1"
    :defaultItem="documento"
    @onInput="getDocumentos"
    @selectItem="$emit('selected', $event)">
   </vue3-simple-typeahead>
   <div class="d-flex loader-input-document">
    <i v-if="search && !loaderInputDocumento" title="Click para buscar cliente" 
    class="fa-solid fa-magnifying-glass c-pointer" @click="activeSearchClienteModal = true"></i>
    <span v-if="loaderInputDocumento" class="spinner-border spinner-border-sm c-wait" role="status"></span>
   </div>
  
   <label for="floatingInput3" :class="required ? 'field-required' : ''">{{title ? title : 'Documento'}}</label>
  </div> 

  <ModalSearchCliente class="animate__animated animate__fadeIn animate__faster" 
  :modo="1" 
  @toggleModalFiltradoClientes="activeSearchClienteModal = !activeSearchClienteModal"
  @dataClienteFilter="setClienteFiltrado($event)" 
  v-if="activeSearchClienteModal"/>
</div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {getDocumentosClientes} from '@/helpers'
const reloadTypehead = ref(true)

const props = defineProps({
    title: '',
    documento: '',
    required: '',
    search: Boolean
})


const documento = ref(props.documento)
const emit = defineEmits(['selected'])
const activeSearchClienteModal = ref(false)
const loaderInputDocumento = ref(false)
const documentosClientes = ref([])


const getDocumentos = async (e) => {
    loaderInputDocumento.value = true
    const response = await getDocumentosClientes(e.input) 
    loaderInputDocumento.value = false 
    if(response.length == 0){
        documentosClientes.value = []
        emit('selected', {id: null, documento: e.input})
    }else{
        documentosClientes.value = response
    }
}


const reloadInput = () => {
    reloadTypehead.value = false
    setTimeout(() => {
    reloadTypehead.value = true
   }, 10);
}

const setClienteFiltrado = (data) => {
   documento.value = data
   reloadInput()
   emit('selected', data)
}

watch(() => props.documento.documento, newVal => {
    if(newVal){
        reloadInput()
    }
});
</script>

<style scoped>
.loader-input-document{
    position:absolute; 
    top: 0.8rem; 
    right: 1rem; 
    padding: 0.6rem;
}

.c-wait{
    cursor: wait;
}
</style>