<template>   
<div>
  <div class="card">
  <div class="card-body">

<div class="d-flex">
    
<div class="header-btn-actions">
 <h5 class="text-uppercase text-start fs-bold">Couriers</h5>

<div class="d-flex" v-if="actions">
    
<button
  title="Ingresar nuevo Registro"
  @click="nuevoRegistro"
  class="btn btn-primary btn-header-tabla ms-2"
>
  <i class="fas fa-plus-square"></i>
</button>

<button
  title="Refrescar Data"
  @click="getCouriers"
  class="btn btn-primary btn-header-tabla ms-2"
>
  <i class="fa-solid fa-arrows-rotate"></i>
</button>
</div>
</div>  
  <i class="fas fa-truck icon-consolidado" aria-hidden="true"></i>
  </div>


<div class="scroll-card-big mt-5">

<div class="d-flex status-control" v-if="actions">
  <button class="nav-link" :class="courierStatus == 1 ? 'text-dark' : ''" @click="courierStatus = 1">Activos</button>
  <button class="nav-link" :class="courierStatus == 0 ? 'text-dark' : ''" @click="courierStatus = 0">Inactivos</button>
</div>

<!-- Componente Data -->
<div v-if="loading" class="animate__animated animate__fadeIn">

  <div class="card w-100 mb-3" v-for="item in 3" :key="item">
        <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
            <div class="PicLoaderCircular animate din"></div>
            <div class="d-flex flex-column">
             <div class="comment br animate mb-2" style="height: 20px"></div>

          <div class="d-flex justify-content-center">

             <div class="PicLoaderCircular animate" style="width: 40px; height: 40px"></div>
              <div class="PicLoaderCircular animate ms-1" style="width: 40px; height: 40px"></div>
             </div>  

            </div>
          </div>
          </div>
    </div>


</div>



  <div v-else class="animate__animated animate__fadeIn">

   <div v-if="courierStatus == 1" class="card w-100 mb-3 c-pointer" v-for="item in couriers" :key="item.id"  @click="verOficinas(item.id,item.nombre,item.pais)">
        <div class="card-body">

        <div class="row justify-content-center align-items-center">

            <div class="col-md-6">
            <h6 class="text-uppercase f-bold pe-none">{{item.nombre}}</h6>
            </div>



            <div class="col-md-6 content-courier-actions">
           <div class="d-flex flex-column">

            <img 
            class="logo-courier m-auto"
            :src="item.logo"
            @error="errorImg"
            alt="">


          <div class="d-flex justify-content-center btn-actions" v-if="actions">
              <button 
               title="Ver Oficinas"
               class="btn btn-dark b-radius">
                 <i class="fas fa-building"></i>
              </button>

              <button 
               title="Editar"
               @click="Editar(item)"
               class="btn btn-info b-radius text-white ms-1">
                 <i class="fas fa-edit"></i>
              </button>

               <button 
               v-if="$auth.user.rol === 'admin'"
               title="Eliminar"
               @click="Eliminar(item.id)"
               class="btn btn-danger b-radius ms-1">
                 <i class="fas fa-trash-alt"></i>
              </button>
 </div>

             </div>  
            </div>
    

            </div>

          </div>
   </div>


   <div v-if="courierStatus == 0" class="card w-100 mb-3 c-pointer" v-for="item in couriersDisabled" :key="item.id"  @click="verOficinas(item.id,item.nombre,item.pais)">
    <div class="card-body">

    <div class="row justify-content-center align-items-center">

        <div class="col-md-6">
        <h6 class="text-uppercase f-bold pe-none">{{item.nombre}}</h6>
        </div>



        <div class="col-md-6 content-courier-actions">
       <div class="d-flex flex-column">

        <img 
        class="logo-courier m-auto"
        :src="item.logo"
        @error="errorImg"
        alt="">


      <div class="d-flex justify-content-center btn-actions" v-if="actions">
          <button 
           title="Ver Oficinas"
           class="btn btn-dark b-radius">
             <i class="fas fa-building"></i>
          </button>

          <button 
           title="Editar"
           @click="Editar(item)"
           class="btn btn-info b-radius text-white ms-1">
             <i class="fas fa-edit"></i>
          </button>

           <button 
           v-if="$auth.user.rol === 'admin'"
           title="Eliminar"
           @click="Eliminar(item.id)"
           class="btn btn-danger b-radius ms-1">
             <i class="fas fa-trash-alt"></i>
          </button>
</div>

         </div>  
        </div>


        </div>

      </div>
    </div>

    
  </div>

  </div>
<!-- Fin Componente Data -->

    </div>

 
  </div> <!-- Fin Template -->


<Modal :titulo="nombreModal" @close="toggleModal" :modalActive="modalActive" v-if="actions">
    <div>
              

            <div class="row">


              <div class="col-12">
                <div class="form-check">
                  <input type="checkbox" class="c-pointer" v-model="status" id="statusCourier" style="width: 20px; height: 20px;">
                  <label class="ms-2 fw-bold fs-xl-2 c-pointer" for="statusCourier">Habilitado</label>
              </div>
              </div>

              <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="nombre" type="text" class="form-control" id="floatingInput2" placeholder="Nombre Courier" autocomplete="off">
               <label for="floatingInput2">Nombre Courier  <span class="text-danger fw-bold">[*]</span></label>
              </div>
                </div>



              <div class="col-md-6">
             <SelectPaises :pais_select="pais" @onSetPais="setPaisSelect($event)"  v-if="modalActive"/>
              </div>

            </div>
          

              <div class="form-floating mb-3">
               <input v-model="logoUrl" type="text" class="form-control" id="floatingInput3" placeholder="Logo Url" autocomplete="off">
               <label for="floatingInput3">Url Logo Courier (Opcional)</label>
               </div>
           

             
               <div class="form-floating mb-3">
               <input v-model="web" type="text" class="form-control" id="floatingInput4" placeholder="Web Courier" autocomplete="off">
               <label for="floatingInput4">Web Courier (Opcional)</label>
               </div>
       



              </div>
 

            <div class="buttons-modal-footer">
            <button v-if="modoModal==0" @click="Crear" type="button" class="btn-modal-crear w-90 b-radius">Crear</button>
            <button v-if="modoModal==1" @click="actualizarRegistro" type="button" class="btn-modal-crear w-90 b-radius">Actualizar</button>
             </div>
            </Modal>


  <ModalConfirm @close="toggleModalConfirm" :modalActive="modalActiveConfirm" v-if="actions">
      <h5 class="text-uppercase">¿Estas seguro de eliminar el registro de {{modulo}} ID: {{id_registro}}?</h5>
       <div class="buttons-modal-footer">
          <button @click="toggleModalConfirm" type="button" class="bg-dark text-white">Cancelar</button>
          <button @click="EliminarRegistro" type="button" class="btn-modal-crear bg-danger">Si, Seguro</button>
     </div>
   </ModalConfirm>


</div>
</template>

<script setup>
const props = defineProps({
  actions: Boolean
})
import axios from 'axios'
import { useToast } from 'vue-toastification';
const toast = useToast();
import {ref, onMounted, defineEmits} from 'vue'
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
const couriers = ref([]);
const modulo = ref("courier");
const loading = ref(true)
import Modal from '@/components/modals/Modal.vue'
const nombreModal = ref(null)
const modalActive = ref(false);
import ModalConfirm from '@/components/modals/Modal-Confirm.vue'
const modalActiveConfirm = ref(false);
import SelectPaises from '@/components/dashboard/selectPaises.vue'
const modalActiveSelectPaises = ref(false)
const id_registro = ref(null)
const nombre = ref('')
const logoUrl = ref('')
const status = ref()
const web = ref('')
const modoModal = ref('')
const paises = ref([])
const pais = ref('')
const couriersDisabled = ref([])
const courierStatus = ref(1)


const errorImg = (e) => {
    e.target.src =
      "/favicon.png";
}

const emit = defineEmits(['courierSelected'])

const verOficinas = (id,courier,pais) => {
let data = {
  id: id,
  courier: courier,
  pais: pais
}
emit('courierSelected', data)
if(document.querySelector('.btn-refresh-offices-courier')){
if(props.actions == true){
document.querySelector('.btn-refresh-offices-courier').click();
}
}
}

const nuevoRegistro = () => {
  modoModal.value = 0
  resetModal()
  toggleModal()
}


const toggleModal = () => {
      if(modoModal.value == 0){
        nombreModal.value = 'Creacion De Courier'
      }else{
         nombreModal.value = 'Actualizacion Courier'
      }
     
      modalActive.value = !modalActive.value;
};


const resetModal = () => {
nombre.value = ''
logoUrl.value  = ''
web.value  = ''
} 


const getCouriers = async () => {
loading.value =  true;
let response = (await axios.get(`${modulo.value}`)).data
loading.value = ! loading.value
if(response.length == 0){
  return
}
couriers.value = response.filter(item => item.status == 1)
couriersDisabled.value = response.filter(item => item.status == 0)
verOficinas(response[0].id,  response[0].nombre, response[0].pais)
if(document.querySelector('.btn-refresh-offices-courier')){
if(props.actions == true){
document.querySelector('.btn-refresh-offices-courier').click();
}
}
}


onMounted(async () => {
  getCouriers()
})


const toggleModalConfirm = () => {
      modalActiveConfirm.value = !modalActiveConfirm.value;
};


const setPaisSelect = (e) => {
  pais.value = e
}

const Crear = () => {
let loader = $loading.show();

let jsonData = {
   nombre: nombre.value,
   logo: logoUrl.value,
   web: web.value,
   pais: pais.value
  }
  axios.post(modulo.value, jsonData)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              getCouriers()
              toggleModal()
              resetModal()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Creando Courier')
    });

}



const actualizarRegistro = () => {
  let loader = $loading.show();
  let jsonData = {
   status: status.value,
   nombre: nombre.value,
   logo: logoUrl.value,
   web: web.value,
   pais: pais.value
  }
  axios.put(`${modulo.value}/${id_registro.value}`, jsonData)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              getCouriers()
              toggleModal()
              resetModal()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Actualizando Courier')
    });
}

const getCourierById = async (courier) => {
  /*let response = await axios.get(`${modulo.value}/${id}`)
  nombre.value = response.data.nombre
  logoUrl.value = response.data.logo
  web.value = response.data.web
  pais.value = response.data.pais*/
  status.value = courier.status == 0 ? false : true
  nombre.value = courier.nombre
  logoUrl.value = courier.logo
  web.value = courier.web
  pais.value = courier.pais
}



const Editar = (courier) => {
  modoModal.value = 1
  id_registro.value = courier.id
  toggleModal()
  getCourierById(courier)
}

const Eliminar = (id) => {
  id_registro.value = id
  toggleModalConfirm()
}

const EliminarRegistro = () => {
 let loader = $loading.show();
  axios.delete(modulo.value + `/${id_registro.value}`)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              getCouriers()
              toggleModalConfirm()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error eliminando courier')
    });
}
</script>