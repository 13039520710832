import { defineStore } from 'pinia'

export const useProductsCartStore = defineStore('productsCart', {
  state: () => {
    return { 
        pais: '',
        articulos: [],
        total: 0
    }
  },
  actions: {

    addProduct(producto = '', divisa = '', divisa_simbolo = '', cantidad = 0, costo = 0,  id_categoria  = 0, id_subcategoria  = 0, bateria = 0) {
        for(var item in this.articulos) {
          if(this.articulos[item].producto.toLowerCase() === producto.toLowerCase()) {
            this.articulos[item].cantidad ++;
            this.totalProductsCart()
            return;
          }
        }
        var item = {
            id: Date.now(),
            producto: producto.toLowerCase(),
            pais: this.pais,
            divisa: divisa,
            divisa_simbolo: divisa_simbolo,
            costo: costo,
            categoria: id_categoria,
            subcategoria: id_subcategoria,
            cantidad: cantidad,
            bateria: bateria
        };

        this.articulos.push(item);
        this.totalProductsCart()
      },

      updateProduct(producto) {
        var item = {
            id: producto.id,
            producto: producto.nombre.toLowerCase(),
            pais: this.pais,
            divisa: producto.divisa,
            divisa_simbolo: producto.divisa_simbolo,
            costo: producto.costo,
            categoria: producto.id_categoria,
            subcategoria: producto.id_subcategoria,
            cantidad: producto.cantidad,
            bateria: producto.bateria
        };

        let filter = this.articulos.filter(p => p.id == producto.id)[0]
        if(filter){
          this.articulos.filter(p => p.id == producto.id)[0].producto = producto.nombre
          this.articulos.filter(p => p.id == producto.id)[0].divisa = producto.divisa
          this.articulos.filter(p => p.id == producto.id)[0].divisa_simbolo = producto.divisa_simbolo
          this.articulos.filter(p => p.id == producto.id)[0].costo = producto.costo
          this.articulos.filter(p => p.id == producto.id)[0].categoria = producto.id_categoria
          this.articulos.filter(p => p.id == producto.id)[0].subcategoria = producto.id_subcategoria
          this.articulos.filter(p => p.id == producto.id)[0].cantidad = producto.cantidad
          this.articulos.filter(p => p.id == producto.id)[0].bateria = producto.bateria
          this.totalProductsCart()
        }

      },

    vaciarArticulos(){
        this.pais = ''
        this.articulos = []
        this.total = 0
    },

    
    aumentarArticulos(producto = '') {
        this.addProduct(producto)
        this.totalProductsCart()
    },

    aumentarArticuloByCantidad(producto = '', cantidad = 0){
      for(var i in this.articulos) {
        if (this.articulos[i].producto.toLowerCase() === producto.toLowerCase()) {
          this.articulos[i].cantidad = cantidad;
          break;
        }
      }
      this.totalProductsCart()
    },


    disminuirArticulos(producto = '') {
        for(var item in this.articulos) {
            if(this.articulos[item].producto.toLowerCase() === producto.toLowerCase()) {
              this.articulos[item].cantidad --;
              if(this.articulos[item].cantidad === 0) {
                this.articulos.splice(item, 1);
              }
              break;
            }
        }
        this.totalProductsCart()
      },


      eliminarProducto(producto = ''){
        for(var item in this.articulos) {
          if(this.articulos[item].producto.toLowerCase() === producto.toLowerCase()) {
            this.articulos.splice(item, 1);
            break;
          }
        }
      },


      totalProductsCart () {
        var totalProductos = 0;
        for(var item in this.articulos) {
            totalProductos += this.articulos[item].costo * this.articulos[item].cantidad;
        }
        this.total = Number(totalProductos.toFixed(2));
      },

  }, 
})