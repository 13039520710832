import {createApp} from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import {createPinia} from "pinia";
import axios from "axios";
import auth from "@/plugins/auth";
import defaultLayout from "@/layouts/default.vue";
import autenticadoLayout from "@/layouts/autenticado.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/plugins/chart.js";
import "vue-loading-overlay/dist/css/index.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

import Modal from "@/components/modals/Modal.vue";
import ModalConfirm from "@/components/modals/Modal-Confirm.vue";
import SelectPaises from "@/components/dashboard/selectPaises.vue";
import CouriersComponent from "@/components/courier/Couriers.vue";
import CourierOfficesComponent from "@/components/courier/Courier-Oficina.vue";
import ModalNuevoCliente from "@/components/modals/clientes/Nuevo-Cliente.vue";
import ModalSearchCliente from "@/components/modals/clientes/Search-Cliente.vue";
import ModalClienteDestinatario from "@/components/modals/clientes/destinatarios/Destinatarios.vue";
import ModalNuevoArticulo from "@/components/modals/wizard-guia/Modal-Nuevo-Articulo.vue";
import ModalNuevaTasa from "@/components/modals/dashboard/Modal-Nueva-Tasa-Envios.vue";
import InputCustomer from "@/components/dashboard/InputCustomer.vue";
import Tabla from "@/components/dashboard/Tabla.vue";
import SelectTrackingList from "@/components/dashboard/selects/selectTrackingList.vue";


import {getTimeActual, getDateChartsActual, tiposDocumentos} from "@/helpers";

let jsonHelper = {
	actual_time: getTimeActual().actualTime,
	chart_date: getDateChartsActual().dateChartActual,
	tiposDocs: tiposDocumentos().tipos_documentos,
};

axios.defaults.baseURL = process.env.VUE_APP_URL_API;

axios.interceptors.request.use((config) => {
	NProgress.start();
	return config;
});

axios.interceptors.response.use((response) => {
	NProgress.done();
	return response;
});

createApp(App)
	.provide("helpers", jsonHelper)
	.use(createPinia())
	.use(auth)
	.use(router)
	.component("v-select", vSelect)
	.use(SimpleTypeahead)
	.use(Toast, {
		transition: "Vue-Toastification__fade",
		timeout: 3000,
		position: "top-center",
		hideProgressBar: true,
		maxToasts: 1,
		newestOnTop: false,
		filterBeforeCreate: (toast, toasts) => {
			if (toasts.filter((t) => t.type === toast.type).length !== 0) {
				// Returning false discards the toast
				return false;
			}
			// You can modify the toast if you want
			return toast;
		},
	})

	.component("SelectTrackingList", SelectTrackingList)
	.component("Tabla", Tabla)
	.component("InputCustomer", InputCustomer)
	.component("ModalNuevoCliente", ModalNuevoCliente)
	.component("ModalSearchCliente", ModalSearchCliente)
	.component("ModalClienteDestinatario", ModalClienteDestinatario)
	.component("ModalNuevoArticulo", ModalNuevoArticulo)
	.component("ModalNuevaTasa", ModalNuevaTasa)
	.component("CourierOfficesComponent", CourierOfficesComponent)
	.component("CouriersComponent", CouriersComponent)
	.component("SelectPaises", SelectPaises)
	.component("Modal", Modal)
	.component("ModalConfirm", ModalConfirm)
	.component("default", defaultLayout)
	.component("autenticado", autenticadoLayout)
	.mount("#app");
