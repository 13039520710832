<template>
<div class="row m-auto">


  <div class="col-md-4 mb-3">
    <SelectPaises titulo="Nacionalidad" :pais_select="pais" @onSetPais="setPais($event)"/>
 </div>


<div class="col-md-4" v-if="resetFieldTypeHead">
              <div class="form-floating mb-3" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_estados"
                placeholder="Estado / Provincia"
                :items="estados"
                :defaultItem="estado"
                :minInputLength="1"
                @selectItem="selectEstado"
                @onBlur="setEstado"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3" v-if="false">Estado / Provincia <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
</div>


                           
<div class="col-md-4" v-if="resetFieldTypeHead">
              <div class="form-floating mb-3" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_ciudades"
                placeholder="Ciudad / Distrito"
                :items="ciudades"
                :defaultItem="ciudad"
                :minInputLength="1"
                @selectItem="selectCiudad"
                @onBlur="setCiudad"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3" v-if="false">Ciudad / Distrito <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
 </div>
          

    </div>
</template>

<script setup>
import {ref,reactive,onMounted,defineEmits,defineProps} from 'vue'
import {getEstados, getCiudades} from '@/helpers'

import SelectPaises from '@/components/dashboard/selectPaises.vue'

const props = defineProps({
    data: Array
})

const resetFieldTypeHead = ref(true)

const paises = ref([])
const pais = ref(props.data.pais ? props.data.pais : '')

const estados = ref([])
const estado = ref(props.data.estado ? props.data.estado : '')

const ciudades = ref([])
const ciudad = ref(props.data.ciudad ? props.data.ciudad : '')

const dataPais = reactive({
    pais: pais.value,
    estado: estado.value,
    ciudad: ciudad.value
})

const emit = defineEmits(['paises_select'])

onMounted(async () => {
  emit('paises_select', dataPais)
})


/**** Funcionalidad paises/estados/ciudades */

const setPais = (e) => {
  pais.value = e
  obtenerEstados()
}

const setEstado = (e) => {
   estado.value = e.input
   dataPais.estado = estado.value
   emit('paises_select', dataPais)
}

const setCiudad = (e) => {
  ciudad.value = e.input
  dataPais.ciudad = ciudad.value
  emit('paises_select', dataPais)
}

const selectEstado = async (e) => {
  estado.value = e
  await obtenerCiudades(e)
  document.querySelector("#typeahead_ciudades").focus()
  dataPais.estado = estado.value
  emit('paises_select', dataPais)
}

const selectCiudad = (e) => {
  ciudad.value = e
  dataPais.ciudad = ciudad.value
  emit('paises_select', dataPais)
}

const obtenerEstados = async () => {
  const data = await getEstados(pais.value)
  estados.value = data.array
  document.querySelector("#typeahead_estados").focus()
  dataPais.pais = pais.value
  emit('paises_select', dataPais)
}
  
const obtenerCiudades = async (estado) => {
  const data = await getCiudades(estado)
  ciudades.value = data.array
  emit('paises_select', dataPais)
}
/*************************/
</script>