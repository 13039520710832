<template>

<div>


 <div class="col-12 d-flex justify-content-end p-relative mt-2">
        <button class="btn btn-primary btn-header-tabla btn-reload-data" @click="reloadData" title="Refrescar Tabla">
          <i class="fa-solid fa-arrows-rotate"></i>
        </button>
</div>

    <table id="tabla" class="table table-hover nowrap w-100">
    <thead class="bg-principal-alt text-white">
      <tr>
        <th v-for='columna in columnas' :key="columna">
          {{columna}}
        </th>
         <th>
          ACCIONES
        </th>
      </tr>
    </thead>
    <tbody class="position-relative">
    </tbody>
  </table>


    <ModalConfirm  @close="toggleModalConfirm" :modalActive="modalActiveConfirm">
      <h5 class="text-uppercase">¿Estas seguro de eliminar el registro ID: {{id_registro}}?</h5>
       <div class="buttons-modal-footer">
          <button @click="toggleModalConfirm" type="button" class="bg-dark text-white">Cancelar</button>
          <button @click="Eliminar" type="button" class="btn-modal-crear bg-danger">Si, Seguro</button>
     </div>
   </ModalConfirm>


<ExcelManifiesto @toggleModal="modalExcelManifiesto" :id_manifiesto="id_registro"  v-if="modalActiveExcelManifiesto" /> 
<ExcelCarga @toggleModal="modalExcelCarga" :id_carga="id_registro"  v-if="modalActiveExcelCarga" /> 

</div>
</template>
<script setup>
import axios from 'axios'
import ModalConfirm from '@/components/modals/Modal-Confirm.vue'
import ExcelManifiesto from '@/components/modals/guias/manifiesto/Modal-Exportar-Excel-Manifiesto.vue'
import ExcelCarga from '@/components/modals/guias/cargas/Modal-Exportar-Excel-Carga.vue'
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import jszip from "jszip/dist/jszip"
import "datatables.net-responsive-dt/css/responsive.dataTables.css"
import "datatables.net-responsive-dt/js/responsive.dataTables"
import $ from 'jquery'; 
import {ref, onMounted} from 'vue'
import {useLoading} from 'vue-loading-overlay'
import { useToast } from "vue-toastification"
import {useRouter} from 'vue-router'
import logoZoom from '@/assets/img/recursos/logo-zoom.png'
const $loading = useLoading();
const modalActiveConfirm = ref(false);
const modalActiveExcelManifiesto = ref(false)
const id_registro = ref(0);
const props = defineProps({
  nombre: '',
  modulo: '',
  columnas: Array,
  columnasBd: Array,
  options: Array
})

const toast = useToast();
const Router = useRouter()
const modulo = props.modulo

window.JSZip = jszip
let tabla

let btnEdit 
let btnDelete
let btnExcelManifiesto
let btnPdfManifiesto
let btnExcelCarga
let btnPdfCarga
let btnCourierZoom
let btnGroup
let btnActions = {
        data: null,
        render: function (data) {
          if(props.options){
            if(props.options[0].edit == true){
              btnEdit = `
              <button 
               title="Editar"
               class="btn btn-info btn-edit b-radius text-white">
                 <i class="fas fa-edit"></i>
              </button>
              `
              btnGroup += btnEdit
            }else{
              btnEdit = ''
            }
            if(props.options[0].delete == true){
              btnDelete = `       
               <button 
               title="Eliminar"
               class="btn btn-danger btn-eliminar b-radius ms-2">
               <i class="fa-regular fa-trash-can"></i>
              </button>
              `
              btnGroup += btnDelete
            }else{
              btnDelete = ""
            }


            if(props.options[0].pdfManifiesto == true){
              btnPdfManifiesto = `       
               <button 
               title="Descargar pdf manifiesto"
               class="btn btn-danger btn-pdf-manifiesto b-radius ms-2">
               <i class="fa-solid fa-file-pdf"></i>
              </button>
              `
              btnGroup += btnPdfManifiesto
            }else{
              btnPdfManifiesto = ""
            }

             if(props.options[0].excelManifiesto == true){
              btnExcelManifiesto = `       
               <button 
               title="Descargar excel manifiesto"
               class="btn btn-success btn-excel-manifiesto b-radius ms-2 me-2">
               <i class="fa-solid fa-file-excel"></i>
              </button>
              `
              btnGroup += btnExcelManifiesto
            }else{
              btnExcelManifiesto = ""
            }

  


              if(props.options[0].pdfCarga == true){
              btnPdfCarga = `       
               <button 
               title="Descargar pdf carga"
               class="btn btn-danger btn-pdf-carga b-radius ms-2">
             <i class="fa-solid fa-file-pdf"></i>
              </button>
              `
              btnGroup += btnPdfCarga
            }else{
              btnPdfCarga = ""
            }


              if(props.options[0].excelCarga == true){
              btnExcelCarga = `       
               <button 
               title="Descargar excel carga"
               class="btn btn-success btn-excel-carga b-radius ms-2 me-2">
              <i class="fa-solid fa-file-excel"></i>
              </button>
              `
              btnGroup += btnExcelCarga
            }else{
              btnExcelCarga = ""
            }


            if(props.options[0].courierZoom == true){
              btnCourierZoom = `       
               <button 
               title="Conectar guías de carga con zoom"
               class="btn btn-light btn-courier-zoom b-radius shadow me-2">
              <img style="width: 70px;" src="${logoZoom}" /> 
              </button>
              `
              btnGroup += btnCourierZoom
            }else{
              btnCourierZoom = ""
            }

            return (`

           <div class="d-flex justify-content-center">
             ${btnPdfManifiesto} ${btnExcelManifiesto} ${btnPdfCarga} ${btnExcelCarga} ${btnCourierZoom} ${btnEdit} ${btnDelete}
           </div>
            `)


          }else{
          return (
           `
           <div class="d-flex justify-content-center">

               <button 
               title="Editar"
               class="btn btn-info btn-edit b-radius text-white">
                 <i class="fas fa-edit"></i>
              </button>

               <button 
               title="Eliminar"
               class="btn btn-danger btn-eliminar b-radius ms-2">
               <i class="fa-regular fa-trash-can"></i>
              </button>

             </div>  
             `
          )

          }
        },
}

props.columnasBd.push(btnActions)


const toggleModalConfirm = () => {
      modalActiveConfirm.value = !modalActiveConfirm.value;
};

const pdfManifiesto = async () => {
let loader = $loading.show();
let pdfData = (await axios.get(`documentos-guia/manifiesto/${id_registro.value}`, {
   responseType: 'arraybuffer'
})).data
loader.hide()
if(pdfData.status !== 'error'){
let blob = new Blob([pdfData], { type: 'application/pdf' })
let link = document.createElement('a')
link.href = window.URL.createObjectURL(blob)
link.download = 'Manifiesto-' + id_registro.value + '.pdf'
link.click()
}
id_registro.value = ''
}

const modalExcelManifiesto = () => {
      modalActiveExcelManifiesto.value = !modalActiveExcelManifiesto.value;
};


const modalActiveExcelCarga = ref(false)
const modalExcelCarga= () => {
      modalActiveExcelCarga.value = !modalActiveExcelCarga.value;
};


const pdfCarga = async () => {
let loader = $loading.show();
let pdfData = (await axios.get(`documentos-guia/carga/${id_registro.value}`, {
   responseType: 'arraybuffer'
})).data
loader.hide()
if(pdfData.status !== 'error'){
let blob = new Blob([pdfData], { type: 'application/pdf' })
let link = document.createElement('a')
link.href = window.URL.createObjectURL(blob)
link.download = 'ManifiestoCarga-' + id_registro.value + '.pdf'
link.click()
}
id_registro.value = ''
}


const loaderTable = (texto) => {
  let templateLoader = `
     <div class="d-flex align-items-center centrar mt-4">
        <span class="spinner-border spinner-border-sm" role="status"></span>
        <h5 class="mb-0">${texto}</h5>
      </div>
  `
  $("#tabla tbody").html(templateLoader)
}


onMounted(() => {
  loaderTable('Cargando Data...')
  tabla = $("#tabla").DataTable({
      ajax: {
      headers: { Authorization: "Bearer " + localStorage.getItem('auth_token') },
      url: process.env.VUE_APP_URL_API + props.modulo,
      dataSrc: "",
     },
    columns: props.columnasBd,
    pageLength: 10,
    lengthMenu: [
      [5, 10, 20, 30, -1],
      [5, 10, 20, 30, "Todos"],
    ],
    language: {
      lengthMenu: "Mostrar _MENU_ registros",
      zeroRecords: "No se encontraron resultados",
      info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
      infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
      infoFiltered: "(filtrado de un total de _MAX_ registros)",
      sSearch: "Buscar:",
      oPaginate: {
        sFirst: "Primero",
        sLast: "Último",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      sProcessing: "Procesando...",
    },
    responsive: true,
    dom: 'Bfrtip',
    buttons:[ 
			{
				extend:    'excelHtml5',
				text:      '<i class="fas fa-file-excel"></i> ',
				titleAttr: 'Exportar a Excel',
				className: 'btn btn-success',
        exportOptions: {
          columns: ':not(:last-child)',
      }
			},
			{
				extend:    'pdfHtml5',
				text:      '<i class="fas fa-file-pdf"></i> ',
				titleAttr: 'Exportar a PDF',
				className: 'btn btn-danger',
        exportOptions: {
          columns: ':not(:last-child)',
      }
			}
		],
    "order": [[0, 'desc']],
       "initComplete" : function (setting, json){
           props.columnasBd.pop()
    }
   })


$("#tabla tbody").on("click",'.btn-edit', function(e){
  e.preventDefault()
   let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }

   id_registro.value = row.find("td:eq(0)").text()
   Router.push(`/${modulo}/${id_registro.value}/edit`);
})



$("#tabla tbody").on("click",'.btn-courier-zoom', function(e){
  e.preventDefault()
   let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }

   id_registro.value = row.find("td:eq(0)").text()
   Router.push(`/${modulo}/${id_registro.value}/zoom`);
})


/**** Manifiesto generacion documentos */
$("#tabla tbody").on("click",'.btn-pdf-manifiesto', function(e){
  e.preventDefault()
  let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }
  id_registro.value = row.find("td:eq(0)").text()
  pdfManifiesto()
})

$("#tabla tbody").on("click",'.btn-excel-manifiesto', function(e){
  e.preventDefault()
  let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }
  id_registro.value = row.find("td:eq(0)").text()
  modalExcelManifiesto()
})
/**************************/


/**** Carga generacion documentos */
$("#tabla tbody").on("click",'.btn-pdf-carga', function(e){
  e.preventDefault()
  let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }
  id_registro.value = row.find("td:eq(0)").text()
  pdfCarga()
})

$("#tabla tbody").on("click",'.btn-excel-carga', function(e){
  e.preventDefault()
  let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }
  id_registro.value = row.find("td:eq(0)").text()
  modalExcelCarga()
})
/*********************/

$("#tabla tbody").on("click",'.btn-eliminar', function(e){
  e.preventDefault()
  let target = e.currentTarget
   var row
   if ($(target).closest("table").hasClass("collapsed")) {
    var child = $(target).parents("tr.child")
      row = $(child).prevAll(".parent")
   } else {
      row = $(target).parents("tr")
   }

  id_registro.value =row.find("td:eq(0)").text()
  toggleModalConfirm()
})


})



const modalActive = ref(false);

const toggleModal = () => {
      modalActive.value = !modalActive.value;
};


const Eliminar = () => {
 let loader = $loading.show();
axios.delete(props.modulo + `/${id_registro.value}`)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              toggleModalConfirm()
              reloadData()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error eliminando registro')
    });
}

const reloadData = () => {
   loaderTable('Recargando Data')
   var urlApi = process.env.VUE_APP_URL_API + props.modulo
   tabla.ajax.url(urlApi).load(function (json) {
  })
 
  }
</script>