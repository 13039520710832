<template>
<div>

<div class="card">
  <div class="card-body">

<div class="d-flex">

<div class="header-btn-actions">
 <h5 class="text-uppercase text-start fs-bold">Oficinas ({{officesData.length}})</h5>


<div class="d-flex" v-if="actions">
<button
  v-if="!loading"
  title="Ingresar nuevo Registro"
  @click="nuevoRegistro"
  class="btn btn-primary btn-header-tabla ms-2"
>
  <i class="fas fa-plus-square"></i>
</button>

<button
  title="Refrescar Data"
  @click="getOfficesCouriers"
  class="btn btn-primary btn-header-tabla btn-refresh-offices-courier ms-2"
>
  <i class="fa-solid fa-arrows-rotate"></i>
</button>
</div>
</div>    

  <i class="fas fa-building icon-consolidado" aria-hidden="true"></i>
</div>



 


  <div class="form-floating col-12 mt-5 mb-3">
  <input id="inputSearch" v-model="searchOffice" type="search" 
  class="form-control f-bold text-uppercase"
  placeholder="Escriba su coincidencia de búsqueda" autocomplete="off">
   <label for="inputSearch">FIltro de búsqueda</label>
  </div>



    <div class="scroll-card-big" style="height: 345px">
        <div class="animate__animated animate__fadeIn centrar" v-if="loading">
            <h3 class="text-uppercase">Cargando Oficinas 
              <span class="spinner-border me-2 spinner-border-sm" role="status"></span>
            </h3>
        </div>

        <div class="animate__animated animate__fadeIn centrar" v-if="officesData.length === 0 && !loading">
            <h3 class="text-uppercase">Courier {{courierSelecionado.courier}} Sin oficinas <h3 v-if="searchOffice">En {{searchOffice}}</h3></h3>
        </div>



        <div :id="'courierOffice' + item.id" class="card card-oficina-courier w-100 mb-3 animate__animated animate__fadeIn" :class="item.id == selectOficina.id ? 'active' : ''" v-else v-for="item in officesData" :key="item.id"
        @click="selectOficinaCourier(item)"
        >
        <div class="card-body">

        <div class="row justify-content-center">

            <div class="col-md-8">
            <div class="d-flex-flex-column m-2">
            <h6 class="text-uppercase f-bold pe-none">
              {{item.courier.nombre}}
              <small v-if="item.cod_oficina">({{item.cod_oficina}})</small>
              {{!item.estado || item.estado !== 'null' ? ' - ' + item.estado : ''}}
            </h6>

              <p class="text-center">
                {{item.direccion}} <br><b>({{item.ciudad.toUpperCase()}})</b>
                </p>
            </div>
            </div>

            <div class="col-md-4 content-courier-actions">
           <div class="d-flex flex-column">
            <img 
            class="logo-courier m-auto"
            :src="item.courier.logo"
            @error="errorImg"
            alt="">

             <div class="d-flex justify-content-center btn-actions" v-if="actions">
              <button 
               title="Editar"
               @click="Editar(item.id)"
               class="btn btn-info b-radius text-white ms-1">
                 <i class="fas fa-edit"></i>
              </button>

               <button 
               v-if="$auth.user.rol === 'admin'"
               title="Eliminar"
               @click="Eliminar(item.id)"
               class="btn btn-danger b-radius ms-1">
                 <i class="fas fa-trash-alt"></i>
              </button>
             </div>
           </div>
            </div>


          </div>
          </div>
        </div>

      </div>


  


    </div>
  </div> <!-- Fin Template Offices -->



<Modal :titulo="nombreModal" @close="toggleModal" :modalActive="modalActive" v-if="actions">
    <div class="h-agml-scroll">
              
         <div class="row">


         <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="id_registro" type="text" class="form-control" id="inputIdOficina" placeholder="Id Oficina" autocomplete="off" disabled>
               <label for="inputIdOficina">Id</label>
              </div>
            </div>

            <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="cod_oficina" type="text" class="form-control" id="inputCodOficina" placeholder="Codigo Oficina" autocomplete="off">
               <label for="floatingInput2">Cod Oficina <span class="text-danger fw-bold">[*]</span></label>
              </div>
            </div>

        


              <div class="col-md-4">
               <div class="form-floating mb-3">
               <input v-model="pais" type="text" class="form-control" id="floatingInput4" placeholder="Pais" autocomplete="off" disabled>
               <label for="floatingInput4">Pais</label>
               </div>
              </div>


              <div class="col-md-4" v-if="resetFieldTypeHead">
              <div class="form-floating mb-3" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_estados"
                placeholder=""
                :items="estados"
                :defaultItem="estado"
                :minInputLength="1"
                @selectItem="selectEstado"
                @onBlur="setEstado"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3">Estado / Provincia <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
              </div>


                           
         <div class="col-md-4" v-if="resetFieldTypeHead">
              <div class="form-floating mb-3" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_ciudades"
                placeholder=""
                :items="ciudades"
                :defaultItem="ciudad"
                :minInputLength="1"
                @selectItem="selectCiudad"
                @onBlur="setCiudad"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3">Ciudad / Distrito <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
          </div>
          
        

              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="direccion" type="text" class="form-control" id="inputDireccion" placeholder="Direccion" autocomplete="off">
               <label for="floatingInput4">Direccion <span class="text-danger fw-bold">[*]</span></label>
               </div>
             </div>


             <div class="col-md-4 mt-3">
             <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" :checked="franquicia" v-model="franquicia">
            <label class="form-check-label text-capitalize" for="flexSwitchCheckDefault1">Franquicia?</label>
            </div>
             </div>

            <div class="col-md-4 mt-3">
             <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" :checked="estatus" v-model="estatus">
            <label class="form-check-label text-capitalize" for="flexSwitchCheckDefault2">Oficina Activa? <span class="text-danger fw-bold">*</span></label>
            </div>
             </div>

             
            <div class="col-md-4 mt-3 mb-3">
             <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault3" :checked="entrega_pp" v-model="entrega_pp">
            <label class="form-check-label text-capitalize" for="flexSwitchCheckDefault3">puerta a puerta?</label>
            </div>
             </div>
       
       

             <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="razon_social" type="text" class="form-control" id="floatingInput4" placeholder="Razon Social" autocomplete="off">
               <label for="floatingInput4">Razon Social</label>
               </div>
             </div>


              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="rif" type="text" class="form-control" id="floatingInput4" placeholder="Rif" autocomplete="off">
               <label for="floatingInput4">Rif</label>
               </div>
             </div>


             
              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="contacto" type="text" class="form-control" id="floatingInput4" placeholder="Contacto" autocomplete="off">
               <label for="floatingInput4">Contacto <span class="text-danger fw-bold">[*]</span></label>
               </div>
             </div>




              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="correo" type="text" class="form-control" id="floatingInput4" placeholder="Correo" autocomplete="off">
               <label for="floatingInput4">Correo <span class="text-danger fw-bold">[*]</span></label>
               </div>
             </div>


             
              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="telefonos" type="text" class="form-control text-center" id="floatingInput4" placeholder="Telefonos" autocomplete="off">
               <label for="floatingInput4">Telefonos <span class="text-danger fw-bold">[*]</span></label>
               </div>
             </div>


             
              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="horario_atencion" type="text" class="form-control text-center" id="floatingInput4" placeholder="Horarios Atencion" autocomplete="off">
               <label for="floatingInput4">Horarios Atencion</label>
               </div>
             </div>



              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="observacion" type="text" class="form-control text-center" id="floatingInput4" placeholder="Observaciones" autocomplete="off">
               <label for="floatingInput4">Observaciones</label>
               </div>
             </div>




            </div>

              </div>
 

            <div class="buttons-modal-footer">
            <button v-if="modoModal==0" @click="Crear" type="button" class="btn-modal-crear w-90 b-radius">Crear</button>
            <button v-if="modoModal==1" @click="actualizarRegistro" type="button" class="btn-modal-crear w-90 b-radius">Actualizar</button>
             </div>
            </Modal>


  <ModalConfirm  @close="toggleModalConfirm" :modalActive="modalActiveConfirm" v-if="actions">
      <h5 class="text-uppercase">¿Estas seguro de eliminar el registro de {{modulo}} ID: {{id_registro}}?</h5>
       <div class="buttons-modal-footer">
          <button @click="toggleModalConfirm" type="button" class="bg-dark text-white">Cancelar</button>
          <button @click="EliminarRegistro" type="button" class="btn-modal-crear bg-danger">Si, Seguro</button>
     </div>
   </ModalConfirm>


</div>
</template>


<script setup>
const props = defineProps({
    select: null,
    courierSelecionado: null,
    actions: Boolean,
})
import {getEstados, getCiudades} from '@/helpers'
import {ref, onMounted, computed, reactive} from 'vue'
import axios from 'axios'
import { useToast } from 'vue-toastification';
const toast = useToast();
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
import Modal from '@/components/modals/Modal.vue'
const nombreModal = ref(null)
const modalActive = ref(false);
import ModalConfirm from '@/components/modals/Modal-Confirm.vue'
const modalActiveConfirm = ref(false);
const id_registro = ref(null)
const textFiltrado = ref('Filtrado por')
const filterOfficeBy = ref('estado')
const cod_oficina = ref('')
const pais = ref('')
const ciudad = ref('')
const resetFieldTypeHead = ref(false)
const ciudades = ref([])
const estado = ref('')
const estados = ref([])
const direccion = ref('')
const entrega_pp = ref(0)
const estatus = ref(0)
const razon_social = ref('')
const rif = ref('')
const telefonos = ref('')
const contacto = ref('')
const horario_atencion = ref('')
const correo = ref('')
const franquicia = ref(0)
const observacion = ref('')
const modoModal = ref('')
const modulo = ref('courier-oficinas')
const oficinas = ref([]);
const loading = ref(true)
const searchOffice = ref('')
const selectOficina = reactive({id: '', courier: ''})
const emit = defineEmits(['oficinaSelected'])


const errorImg = (e) => {
    e.target.src =
      "/favicon.png";
}


/**** Funcionalidad paises/estados/ciudades */

const setEstado = (e) => {
   estado.value = e.input
}


const setCiudad = (e) => {
   ciudad.value = e.input
}

const selectEstado = async (e) => {
  estado.value = e
  await obtenerCiudades(e)
  document.querySelector("#typeahead_ciudades").focus()
}

const selectCiudad = (e) => {
  ciudad.value = e
  document.querySelector('#inputDireccion').focus()
}

const obtenerEstados = async () => {
  const data = await getEstados(props.courierSelecionado.pais)
  estados.value = data.array
}
  
const obtenerCiudades = async (estado) => {
  const data = await getCiudades(estado)
  ciudades.value = data.array
}
/************************/


const officesData = computed(() =>{
  if(!props.courierSelecionado){
    return []
  }

  loading.value = false

  if(props.courierSelecionado.id == null){
      return []
  }

  if(!searchOffice.value){
    return oficinas.value.filter(item => item.courier.id == props.courierSelecionado.id)
  }

  return oficinas.value.filter(
  item => item.courier.id == props.courierSelecionado.id && (
  item.estado.toLowerCase().includes(searchOffice.value.toLowerCase()) ||
  item.ciudad.toLowerCase().includes(searchOffice.value.toLowerCase()) ||
  item.direccion.toLowerCase().includes(searchOffice.value.toLowerCase()) ||
  item.cod_oficina.toLowerCase().includes(searchOffice.value.toLowerCase()
  )
  )
  );
})



const getCourierOfficeById = (id) => {
const Office = oficinas.value.filter(item => item.id == id)[0]
cod_oficina.value = Office.cod_oficina
pais.value = Office.pais
estado.value = Office.estado
ciudad.value = Office.ciudad
direccion.value = Office.direccion
entrega_pp.value = Office.entrega_pp
estatus.value = Office.estatus
razon_social.value =Office.razon_social
rif.value = Office.rif
telefonos.value = Office.telefonos
contacto.value = Office.contacto
horario_atencion.value = Office.horario_atencion
correo.value = Office.correo
franquicia.value = Office.franquicia
observacion.value = Office.observacion
obtenerEstados()
}

onMounted(async () => {
getOfficesCouriers()
if(props.select){
  selectOficinaCourier(props.select)
  let id = 'courierOffice' + props.select.id
  if(!id){
    return
  }
  const elemento = document.getElementById(id);
  elemento.scrollIntoView({
    behavior: 'smooth' 
  });
}
})


const getOfficesCouriers = async () => {
  let response = await axios.get(modulo.value)
  oficinas.value = response.data
}


const nuevoRegistro = () => {
  id_registro.value = ''
  modoModal.value = 0
  pais.value = props.courierSelecionado.pais
  ciudad.value = ''
  obtenerEstados()
  resetModal()
  toggleModal()
  setTimeout(() => {
   document.querySelector("#inputCodOficina").focus()
  }, 100);
}

const toggleModal = () => {
      if(modoModal.value == 0){
        nombreModal.value = 'Creacion De Oficina'
      }else{
         nombreModal.value = 'Actualizacion De Oficina'
      }
      resetFieldTypeHead.value = !resetFieldTypeHead.value
      modalActive.value = !modalActive.value;
};

const resetModal = () => {
cod_oficina.value = ""
ciudad.value = ""
estado.value = ""
direccion.value = ""
entrega_pp.value = false
estatus.value = false
razon_social.value = ""
rif.value = ""
telefonos.value = ""
contacto.value = ""
horario_atencion.value = ""
correo.value = ""
franquicia.value = false
observacion.value = ""
} 

const toggleModalConfirm = () => {
      modalActiveConfirm.value = !modalActiveConfirm.value;
};

const Editar = (id) => {
  modoModal.value = 1
  id_registro.value = id
  getCourierOfficeById(id)
  toggleModal()
  setTimeout(() => {
      document.querySelector("#inputDireccion").focus()
  }, 100);
}

const Eliminar = (id) => {
  id_registro.value = id
  toggleModalConfirm()
}


const Crear = () => {
checkVariables()
let loader = $loading.show();
let jsonData = {
   id_courier: props.courierSelecionado.id,
   cod_oficina: cod_oficina.value,
   ciudad: ciudad.value,
   estado: estado.value,
   direccion: direccion.value,
   entrega_pp: entrega_pp.value,
   estatus: estatus.value,
   razon_social: razon_social.value,
   rif: rif.value,
   telefonos: telefonos.value,
   contacto: contacto.value,
   horario_atencion: horario_atencion.value,
   correo: correo.value,
   franquicia: franquicia.value,
   observacion: observacion.value,
  }
  axios.post(modulo.value, jsonData)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              getOfficesCouriers()
              toggleModal()
              resetModal()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Creando Oficina')
    });

}

const actualizarRegistro = () => {
  checkVariables()
  let loader = $loading.show();
  let jsonData = {
   id_courier: id_registro.value,
   cod_oficina: cod_oficina.value,
   ciudad: ciudad.value,
   estado: estado.value,
   direccion: direccion.value,
   entrega_pp: entrega_pp.value,
   estatus: estatus.value,
   razon_social: razon_social.value,
   rif: rif.value,
   telefonos: telefonos.value,
   contacto: contacto.value,
   horario_atencion: horario_atencion.value,
   correo: correo.value,
   franquicia: franquicia.value,
   observacion: observacion.value,
  }
  axios.put(`${modulo.value}/${id_registro.value}`, jsonData)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              getOfficesCouriers()
              toggleModal()
              resetModal()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Actualizando Oficina Courier')
    });
}

const EliminarRegistro = () => {
 let loader = $loading.show();
  axios.delete(modulo.value + `/${id_registro.value}`)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              getOfficesCouriers()
              toggleModalConfirm()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error eliminando oficina courier')
    });
}


const checkVariables = () => {
  if(franquicia.value == false){
    franquicia.value = 0
  }else{
     franquicia.value = 1
  }

  if(estatus.value == false){
    estatus.value = 0
  }else{
    estatus.value = 1  
  }

  if(entrega_pp.value == false){
    entrega_pp.value = 0
  }else{
     entrega_pp.value = 1
  }
}


const selectOficinaCourier = (e) => {
    selectOficina.id = e.id
    selectOficina.courier = e.courier
    emit('oficinaSelected', e)
}
</script>