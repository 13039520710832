<script setup>
import axios from 'axios';
import { onMounted } from 'vue';
import {useLoading} from 'vue-loading-overlay';
import { useToast } from 'vue-toastification';
const toast = useToast()
const $loading = useLoading();
const props = defineProps({
    id_carga: null
})
const emit = defineEmits(['toggleModal'])


onMounted(async () => {
  let loader = $loading.show()
  try {
    let response  = (await axios.get(`cargas/export-excel/${props.id_carga}`, {
      responseType: 'arraybuffer'
    })).data
    let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'Manifiesto-carga-' + props.id_carga +'.xlsx'    
    link.click()
    loader.hide()
    emit('toggleModal')
  } catch (error) {
    loader.hide()
    emit('toggleModal')
    toast.error("Exeption generada: " + error)
  }
})
</script>