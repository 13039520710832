<script setup>
import axios from 'axios'
import { onMounted} from 'vue'
import {useLoading} from 'vue-loading-overlay'
import { useToast } from 'vue-toastification'
const $loading = useLoading();
const toast = useToast()
const props = defineProps({
    id_manifiesto: null
})
const emit = defineEmits(['toggleModal'])

onMounted(async () => {
    let loader = $loading.show()
    try {
    let response  = (await axios.get(`manifiestos/export-excel/${props.id_manifiesto}`, {
      responseType: 'arraybuffer'
    })).data
    let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'Manifiesto-' + props.id_manifiesto +'.xlsx'    
    link.click()
    loader.hide()
    emit('toggleModal')
    } catch (error) {
      emit('toggleModal')
      loader.hide()
      toast.error("Exeption generada: " + error)
    }

})
</script>